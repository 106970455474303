
import ProjectAPI from '@/api/ProjectAPI';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component({
    name: 'HelpPage',
    components: {},
})
export default class NewProjectTopBar extends Vue {
    @Action('generic/getProjectInfo') private getProjectInfoAPI!: (projectID:number) => Promise<any>;
    private displayHelperPopup = false;
    private displayProjectPopup = false;
    private displayNextArrow = true;
    private displayPrevArrow = false;
    private projectInfo=[];
    private projectID = 1;
    private textContent = [
        'Click CTRL+WIN+Q keys at the same time.',
        'Get code from ROLTEK assistant, input it and click SHARE SCREEN.',
        'Click on Allow access.',
        'Click on allow control.',
    ];

    private get images() {
        return require.context('../../../public/help-page/', false, /\.jpg$/);
    }

    private get allowRoltekHelpChange(){
        const currentUserGroup = String(this.$store.getters['jwtData/currentUserGroup'])
        if(currentUserGroup in ['1','2','3']){
            return true;
        }else{
            return false;
        }
    }

    private get helpPageData() {
        const steps = [];

        for (let i = 0; i < this.textContent.length; i++) {
            steps.push({
                description: this.textContent[i],
                img:
                    i === 3
                        ? require('../../../public/help-desk.svg')
                        : this.getImgLocation(`${this.currentLocale}_${i + 1}.jpg`, `${i + 1}`),
            });
        }
        return steps;
    }

    private get currentLocale() {
        if (this.$i18n == null) {
            return 'sl';
        }
        return this.$i18n.locale;
    }

    private get isMobile() {
        if (/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true;
        }

        if (window.matchMedia('only screen and (max-width: 450px)').matches) {
            return true;
        }

        return false;
    }

    private getImgLocation(imgName: string, imgNumber: string) {
        if (this.images.keys().includes(`./${imgName}`)) {
            return this.images(`./${imgName}`);
        }

        return this.images(`./en_${imgNumber}.jpg`);
    }

    private menuItemClicked(e: Event) {
        if (!this.isMobile) {
            e.preventDefault();
        }
    }

    private async getProjectInfo(){
        // @ts-ignore
        document.querySelector('.ant-popover').style = 'display: none;';
        try{
        let response = await this.getProjectInfoAPI(this.projectID);
        this.projectInfo = response.data;
        this.showProjectPopup();
        }catch (e) {
            this.$notification.error({
                message: this.$t('Project with this ID not found') as string,
                description: (e as Error).message,
            });
            return;
        }        
        
    }

    private async updateProject(){
         try {
            let project = await ProjectAPI.updateRoltekHelp(this.projectID.toString());
        } catch (e) {
            return Promise.reject(e);
        }        
        this.closeProjectModal();
    }

    private closeModal() {
        this.displayHelperPopup = false;
    }
    private closeProjectModal() {
        this.displayProjectPopup = false;
    }

    private showHelperPopup() {
        this.displayHelperPopup = true;
        // @ts-ignore
        document.querySelector('.ant-popover').style = 'display: none;';
    }

    private showProjectPopup() {
        this.displayProjectPopup = true;
    }

    private handleOk() {
        this.displayHelperPopup = false;
    }

    private setArrowVisibility(data: any, index: number) {
        this.displayNextArrow = index !== 3;
        this.displayPrevArrow = index !== 0;
    }
}
